<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-14
 * @Description: 营销管理/平台营销工具/平台卡券活动/详情
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div>
      <ykc-detail :titles="['活动信息']" :className="'all'">
        <div slot="cvBody">
          <div class="form-detail">
            <ykc-form :label-position="'left'" label-width="90px">
              <ykc-detail-item label="活动名称：">
                <span>{{ dealData(detail.activityName) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动类型：">
                <span>充电领取</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动状态：">
                <span>
                  {{ statusLabel(detail.activityStatus) }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item label="活动时间：">
                <span>
                  {{ dealData(detail.activityBeginTime) }} ~{{ dealData(detail.activityEndTime) }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item label="修改时间：">
                <span>{{ dealData(detail.modifiedTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="修改人：">
                <span>{{ dealData(detail.createdAccount) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="创建时间：">
                <span>{{ dealData(detail.createdTime) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="创建人：">
                <span>{{ dealData(detail.createdAccount) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="活动说明：">
                <span class="text-wrap">{{ dealData(detail.activityRemark) }}</span>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <ykc-detail :titles="['活动配置']" :className="'all'">
        <div slot="cvBody">
          <div class="form-detail">
            <ykc-form :label-position="'left'" label-width="100px">
              <ykc-detail-item label="卡券设置：">
                <ykc-table :data="detail.rewardList" :columns="tableColumns"></ykc-table>
              </ykc-detail-item>
              <ykc-detail-item label="限领次数：">
                <span>
                  {{ dealData(detail.limitCount) }}次/人/{{
                    detail.limitType === '1' ? '天' : '周期'
                  }}
                </span>
              </ykc-detail-item>
              <ykc-detail-item label="站点范围：">
                <span>{{ stationRangeLabel(detail.stationScope) }}</span>
              </ykc-detail-item>
              <ykc-detail-item label="用户范围：">
                <span>{{ userRangeLabel(detail.userScope) }}</span>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <div class="onlineConent" v-if="detail.userScope !== '3' || detail.stationScope !== '3'">
        <ykc-detail :isHead="false" :tips="false" :className="'all'">
          <div slot="cvBody">
            <ykc-tabs
              :tabList="tabList"
              :active="activeName"
              headerButtonText="导出"
              :showHeaderButtons="showDownloadBtn"
              @btnClick="clickExport"
              :rightHeaderNoMarginTop="true">
              <div slot="first">
                <ykc-table ref="YkcTable" :data="stationTableData" :columns="stationTableColumns">
                  <ykc-pagination
                    :total="stationTabTotal"
                    slot="pagination"
                    :pageSize.sync="stationTabPageSize"
                    :currentPage="stationTabCurrentPage"
                    @size-change="handleStationTabSizeChange"
                    @current-change="handleStationTabCurrentChange" />
                </ykc-table>
              </div>
              <div slot="second">
                <ykc-table ref="YkcTable" :data="userTableData" :columns="userTableColumns">
                  <ykc-pagination
                    :total="userTabTotal"
                    slot="pagination"
                    :pageSize.sync="userTabPageSize"
                    :currentPage="userTabCurrentPage"
                    @size-change="handleUserTabSizeChange"
                    @current-change="handleUserTabCurrentChange" />
                </ykc-table>
              </div>
            </ykc-tabs>
          </div>
        </ykc-detail>
      </div>
    </div>
  </scroll-layout>
</template>

<script>
  import { couponActivity } from '@/service/apis';
  import { code, offlineExport } from '@/utils';

  export default {
    props: {
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        name: 'ChargeDetail',
        detail: {}, // 详情数据
        // 表格列
        tableColumns: [
          {
            label: '卡券名称',
            prop: 'rewardName',
            width: 240,
          },
          {
            label: '发放规则',
            prop: 'limitPower',
            width: 240,
            formatter: (row, column, value) => {
              return `充电满${value}度赠送`;
            },
            scopedSlots: {
              default: ({ row }) => {
                return `充电满${row.limitPower}度赠送`;
              },
            },
          },

          { label: '发送数量(张/人)', prop: 'rewardCount', width: 240 },
        ],
        tabList: [
          {
            label: '活动站点列表',
            name: 'first',
          },
          {
            label: '活动用户列表',
            name: 'second',
          },
        ],
        stationTableData: [], // 电站列表数据
        stationTableColumns: [],
        userTableData: [],
        userTableColumns: [],
        activeName: 'first',
        stationTabTotal: 10,
        stationTabPageSize: 10,
        stationTabCurrentPage: 1,
        userTabTotal: 10,
        userTabPageSize: 10,
        userTabCurrentPage: 1,
        showDownloadBtn: false,
      };
    },
    computed: {},
    created() {
      this.showDownloadBtn = code('marketing:platform:card:download');
      this.getDetail();
    },
    methods: {
      clickExport(activeName) {
        if (activeName === 'first') {
          offlineExport({
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'chargeActivityStationList',
              activityId: this.activityId,
            },
          });
        } else {
          offlineExport({
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'chargeActivityUserList',
              activityId: this.activityId,
            },
          });
        }
      },
      dicStatus() {
        // 活动状态（1:进行中，2:未开始，3:已结束(手动停用)，4:已结束(活动到期)，5:已结束(卡券不足)，6:已结束(卡券失效)）
        return [
          { id: '1', name: '进行中' },
          { id: '2', name: '未开始' },
          { id: '3', name: '已结束(手动停用)' },
          { id: '4', name: '已结束(活动到期)' },
          { id: '5', name: '已结束(卡券不足)' },
          { id: '6', name: '已结束(卡券失效)' },
        ];
      },
      /**
       * 卡券活动字典对应的文案
       * @param status
       * @returns {string|*|string}
       */
      statusLabel(status) {
        if (!status) {
          return '——';
        }
        const obj = this.dicStatus()?.find(item => item.id === status);
        return obj?.name || '——';
      },
      getDetail() {
        // 有问题 详情缺少remark字段
        const requestDetail = couponActivity.chargeActivityDetail({
          activityId: this.activityId,
        });
        requestDetail.then(res => {
          this.detail = res || {};
          this.fixTabsHeaders();
          this.getUsers();
          this.getStations();
        });
      },
      fixTabsHeaders() {
        if (this.detail.userScope === '1') {
          this.userTableColumns = [
            { label: '客户编码', prop: 'dataId', minWidth: '150px' },
            { label: '客户名称', prop: 'dataName', minWidth: '150px' },
            { label: '客户类型', prop: 'orgTypeText', minWidth: '150px' },
          ];
        } else if (this.detail.userScope === '2') {
          this.userTableColumns = [
            { label: '用户分组', prop: 'dataName', minWidth: '150px' },
            { label: '用户数量', prop: 'userCount', minWidth: '150px' },
          ];
        }
        if (this.detail.stationScope === '1' || this.detail.stationScope === '4') {
          this.stationTableColumns = [
            { label: '电站编码', prop: 'dataId', minWidth: '150px' },
            { label: '电站名称', prop: 'dataName', minWidth: '150px' },
            { label: '电站类型', prop: 'stationTypeText', minWidth: '150px' },
            { label: '归属商户', prop: 'stationOperatorName', minWidth: '150px' },
          ];
        } else if (this.detail.stationScope === '2') {
          this.stationTableColumns = [
            { label: '电站分组', prop: 'dataName', minWidth: '150px' },
            { label: '电站数量', prop: 'stationCount', minWidth: '150px' }, // 这里有点不对
          ];
        }
      },
      stationRangeLabel(stationScope) {
        if (stationScope === '1') {
          return '按商户';
        }
        if (stationScope === '2') {
          return '按电站';
        }
        if (stationScope === '3') {
          return '全部电站';
        }
        if (stationScope === '4') {
          return '按城市';
        }
        return '——';
      },
      userRangeLabel(stationScope) {
        if (stationScope === '1') {
          return '按客户';
        }
        if (stationScope === '2') {
          return '按用户分组 ';
        }
        if (stationScope === '3') {
          return '全部用户';
        }
        return '——';
      },
      /**
       * 获取用户信息
       */
      getUsers() {
        const usersRequest = couponActivity.chargeActivityUsers({
          activityId: this.activityId,
          current: this.userTabCurrentPage,
          size: this.userTabPageSize,
        }); // 详情用户列表
        usersRequest.then(res => {
          this.userTableData = res?.records || [];
          this.userTabTotal = res?.total;
        });
      },
      getStations() {
        const stationsRequest = couponActivity.chargeActivityStations({
          activityId: this.activityId,
          current: this.stationTabCurrentPage,
          size: this.stationTabPageSize,
        });
        stationsRequest.then(res => {
          this.stationTableData = res?.records || [];
          this.stationTabTotal = res?.total;
        });
      },
      handleStationTabSizeChange(size) {
        console.log('使用了sync修饰符', this.size);
        this.stationTabPageSize = size;
        this.getStations();
      },
      handleStationTabCurrentChange(currentPage) {
        console.log('没有使用了sync修饰符', currentPage);
        this.stationTabCurrentPage = currentPage;
        this.getStations();
      },
      handleUserTabSizeChange(size) {
        console.log('使用了sync修饰符', this.pageSize);
        this.userTabPageSize = size;
        this.getUsers();
      },
      handleUserTabCurrentChange(currentPage) {
        console.log('没有使用了sync修饰符', currentPage);
        this.userTabCurrentPage = currentPage;
        this.getUsers();
      },
      /**
       * 获取详情数据
       */
      dealData(data) {
        return data || '—';
      },
    },
  };
</script>
<style lang="scss" scoped>
  .text-wrap {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  .all {
    margin-bottom: 16px;
  }
  .ykc-tree.detailTree {
    padding-top: 0;
  }
</style>
